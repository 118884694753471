import { useCallback, useEffect, useState } from 'react'
import InfoDialog from 'src/components/dialog/InfoDialog'
import { LOCAL_STORAGE_KEYS, RESPONSE_STATUS } from 'src/constants/enum'
import textJP from 'src/langs/ja'
import { PaymentInfoValues } from 'src/models'
import { apiCreateOrUpdatePaymentMethod } from 'src/services/UserService'
import listCredit from 'src/assets/images/list-credit.png'
import { handleNavigateApp } from 'src/utils'
import path from 'src/routers/Path'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearBlock, setBlock } from 'src/stores/blockUI'

const AddPaymentMethodCompleted = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMess, setErrorMess] = useState('')
  const paymentInfoStorage = localStorage.getItem(
    LOCAL_STORAGE_KEYS.PAYMENT_INFO
  )
  const [paymentInfo] = useState(
    paymentInfoStorage ? JSON.parse(paymentInfoStorage) : null
  )

  const savePaymentMethod = useCallback(async () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.PAYJP_TOKEN) || ''

    if (token) {
      try {
        dispatch(setBlock())
        const payload = {
          token: JSON.parse(token)
        }
        const res = await apiCreateOrUpdatePaymentMethod(payload, paymentInfo)
        if (res?.status === RESPONSE_STATUS.SUCCESS) {
          // dispatch save new info
          const {
            last4 = '',
            exp_month = '',
            exp_year = '',
            name = '',
            brand = ''
          } = res?.data?.data || {}
          const expiry_year = exp_year.toString().slice(-2) || ''
          const payload: PaymentInfoValues = {
            last4,
            exp_month,
            exp_year: expiry_year,
            name,
            brand
          }
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.PAYMENT_INFO,
            JSON.stringify(payload)
          )
          localStorage.removeItem(LOCAL_STORAGE_KEYS.PAYJP_TOKEN)
          handleNavigateApp({
            pathMobile: path.close_web,
            pathBrowser: path.home,
            navigate
          })
        }
      } catch (error: any) {
        const message = (textJP.common as any)[error?.response?.data?.message]
        setErrorMess(message)
      } finally {
        dispatch(clearBlock())
      }
    }
  }, [paymentInfo, navigate, dispatch])

  useEffect(() => {
    savePaymentMethod()
  }, [savePaymentMethod])

  return (
    <>
      <InfoDialog
        open={!!errorMess}
        onClose={() =>
          handleNavigateApp({
            pathMobile: path.close_web,
            pathBrowser: path.home,
            navigate
          })
        }
        title={textJP.payment_info.cannot_update_title}
        description={errorMess}
        dialogWrapperClass='pb-25px'
        descriptionClass='el_txt_center el_txt_md'
      >
        <div
          style={{ userSelect: 'none' }}
          className='bl_flex justify_center ut_mt10 ut_mb10'
        >
          <img src={listCredit} alt='' className='h-auto' />
        </div>
      </InfoDialog>
    </>
  )
}

export default AddPaymentMethodCompleted
